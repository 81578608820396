exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alle-kommuner-js": () => import("./../../../src/pages/alle-kommuner.js" /* webpackChunkName: "component---src-pages-alle-kommuner-js" */),
  "component---src-pages-har-ikke-flyt-enda-js": () => import("./../../../src/pages/har-ikke-flyt-enda.js" /* webpackChunkName: "component---src-pages-har-ikke-flyt-enda-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-flyt-js": () => import("./../../../src/pages/om-flyt.js" /* webpackChunkName: "component---src-pages-om-flyt-js" */),
  "component---src-pages-partnere-js": () => import("./../../../src/pages/partnere.js" /* webpackChunkName: "component---src-pages-partnere-js" */),
  "component---src-pages-teori-js": () => import("./../../../src/pages/teori.js" /* webpackChunkName: "component---src-pages-teori-js" */),
  "component---src-templates-group-group-js": () => import("./../../../src/templates/group/group.js" /* webpackChunkName: "component---src-templates-group-group-js" */),
  "component---src-templates-theory-page-theory-page-js": () => import("./../../../src/templates/theoryPage/theoryPage.js" /* webpackChunkName: "component---src-templates-theory-page-theory-page-js" */)
}

